<template>
  <section id="promotion">
    <div class="promotion-banner centralize">
      <img src="../assets/banner_blackfriday.gif" alt="BlackFriday" />
    </div>
    <div class="promotion-sec promotion-bg-black is-vcentered">
      <div class="columns is-multiline is-centered is-vcentered">
        <div class="column is-full centralize promotion-content is-vcentered">
          <div class="columns">
            <div class="column is-half centralize">
              <!-- <video style="height: -webkit-fill-available; width: auto;" src="../assets/video_blackfriday.mp4"></video> -->
              <div class="promotion-level centralize">
                <h1 class="promotion-bigtitle">BLACK FRIDAY</h1>
                <div class="promotion-values">
                  <div class="values-header">
                    <h1 class="header-title">Temporada 2020</h1>
                  </div>
                  <div class="values-container">
                    <h2 class="container-from">R$ 69,90</h2>
                    <h2 class="container-to">R$ 39,90</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half centralize">
              <div class="promotion-level centralize">
                <img
                  src="@/assets/logotipo_green.png"
                  alt="Escala 10"
                  width="150"
                />
                <h1 class="promotion-title">
                  <p>
                    <b-icon icon="check"></b-icon> A plataforma de estatística
                    mais completa do Cartola FC!
                  </p>
                  <p>
                    <b-icon icon="check"></b-icon> Com tudo que você precisa
                    para mitar no fantasy!
                  </p>
                  <p><b-icon icon="check"></b-icon> E com App em 2020!</p>
                </h1>
              </div>
              <div class="promotion-level centralize">
                <div>
                  <router-link
                    to="/login?action=promotionlogin"
                    class="btn bt-main-11"
                    >Já sou cadastrado!</router-link
                  >
                  <router-link
                    to="/login?action=promotioncad"
                    class="btn bt-main-22"
                    >Cadastre-se já!</router-link
                  >
                </div>
                <div class="promotion-description">
                  <p>
                    Se já possui um cadastro é só clicar em "Já sou cadastrado!"
                    e fazer o login.
                  </p>
                  <p>
                    Caso ainda não seja nosso cliente, crie seu cadastro através
                    do botão "Cadastre-se já!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="column is-full centralize promotion-footer">
          <p style="font-weight: bolder">Escala10</p>
          <p>Todos os direitos reservados. 2021</p>
          <p style="text-align: center;">
            Caso tenha alguma dúvida, entre em contato em nosso suporte:
            suporte10@escala10.com.br
          </p>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Promotion",
  created() {
    this.$router.push({ path: "/" });
  },
};
</script>

<style>
#promotion {
  width: 100%;
  height: 100%;
  color: white;
  overflow-y: auto !important;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-image: url("../assets/bg_escala10.png");
  background-position: center;
  background-size: cover;
}
.promotion-columns {
  display: flex;
}
.promotion-column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1.2rem;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #00fffd, rgba(0, 0, 0, 0)) 1 100%;
}
.promotion-align-img img {
  width: 100%;
  border-radius: 10px;
}
.promotion-article {
  text-align: justify;
  font-size: 0.95rem;
  font-weight: normal;
  margin: 0;
}
.promotion-bg-black {
  background-color: rgba(0, 0, 0, 0.8);
}
.promotion-black {
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0.39) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(34%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.39))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0.39) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0.39) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0.39) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0.39) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}
.promotion-sec {
  width: 100%;
  height: 100vh;
  display: table;
  padding-top: 50px;
}
.promotion-banner {
  /*
  position: fixed;
  top: 0px;
  */
  height: 120px !important;
  width: 100%;
  padding: 20px 0;
  background-color: black;
}
.promotion-banner img {
  height: 100% !important;
}
.promotion-content {
  /* margin-top: 100px;
  height: 100vh; */
  height: 85vh;
  padding: 50px 20px;
}
.promotion-footer {
  /* position: fixed;
  bottom: 0;
  height: 15vh; */
  margin: 30px 0;
}
.promotion-footer p {
  margin-bottom: 0;
  font-size: 0.75rem;
}
.promotion-level {
  width: 100%;
  padding: 15px 0;
  margin-bottom: 1rem;
}
.promotion-bigtitle {
  color: white;
  font-size: 5rem;
  text-align: center;
  font-weight: bolder;
  padding-bottom: 25px;
}
.promotion-values {
  border-radius: 7px;
  background-color: #272727;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
}
.promotion-values .values-header {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  /* margin-bottom: 15px; */
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
}
.promotion-values .values-header .header-title {
  margin: 0px !important;
  font-size: 2rem !important;
  color: black !important;
}
.promotion-values .values-container {
  padding: 10px;
  width: 100%;
  text-align: center;
  margin: 25px 0;
}
.promotion-values .values-container h2 {
  padding: 10px 0;
}
.promotion-values .values-container .container-from {
  font-size: 2rem !important;
  text-decoration: line-through;
}
.promotion-values .values-container .container-to {
  font-size: 3.5rem !important;
  font-weight: bolder;
  color: #00fffd;
}
.promotion-title {
  width: 100%;
  color: white;
  font-size: 1.9rem;
  text-align: center;
  font-weight: 400;
  padding: 0 8vw;
}
.promotion-description {
  text-align: center;
  font-size: 0.8rem;
  padding: 15px;
}
.bt-main-11 {
  border-radius: 7px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  color: #00fffd;
  border: 2px solid #00fffd;
  font-weight: bolder;
}
.bt-main-22 {
  margin-left: 20px;
  border-radius: 7px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #00fffd;
  color: #333;
  border: 2px solid #00fffd;
  font-weight: bolder;
}
.bt-main-11:hover {
  background-color: #00fffd;
  color: #333;
}
.bt-main-22:hover {
  background-color: #00e2e2;
  border: 2px solid #00e2e2;
  color: #333;
}

@media (min-width: 342px) and (max-width: 1024px) {
  .promotion-sec {
    padding-top: 0px !important;
  }
  .promotion-content {
    height: auto !important;
  }
  .promotion-columns {
    display: block !important;
  }
  .promotion-bigtitle {
    line-height: 1.2em !important;
    font-size: 4rem !important;
  }
  .promotion-title {
    width: 100%;
    color: white;
    font-size: 1.5rem;
    padding: 0 2vw;
  }
  .promotion-banner {
    height: 50px !important;
    padding: 10px 0;
  }
}

.logo-escala10-green {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../assets/logotipo_green.png");
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}

.logo-escala10-black {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../assets/logotipo_black.png");
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
</style>
